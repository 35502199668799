import React, { useState, useEffect } from 'react';
import '../ErrorBanner.css';

const ErrorBanner = ({ error }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    let timer;

    if (error) {
      setShowBanner(true);

      timer = setTimeout(() => {
        setShowBanner(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
      setShowBanner(false); // Reset showBanner state to false when the component unmounts or when error changes
    };
  }, [error]);

  if (!showBanner) {
    return null;
  }

  return <div className="error-banner">{error}</div>;
};

export default ErrorBanner;
