import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Navbar, Nav, Form, FormControl, OverlayTrigger, Tooltip, Tab } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import ErrorBanner from './ErrorBanner';
import { useDescope, useSession, useUser, getSessionToken } from '@descope/react-sdk';
import { useCallback } from 'react';

function App() {
  const [summaryData, setSummaryData] = useState({});
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState(['']);
  const [searchTerms, setSearchTerms] = useState([]);
  const [activeTab, setActiveTab] = useState('tab1');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Confirm user is logged in

  const { isAuthenticated, isSessionLoading } = useSession();
	const { user, isUserLoading } = useUser();

    // Get user token_sub
    // const sessionToken = getSessionToken();

    const [userId, setUserId] = useState(null);  // Initial state is null
    useEffect(() => {
      if (user && user.userId) {
          setUserId(user.userId);  // Update state when user.userId is available
          console.log(user.userId);
      }
  }, [user]);  // Dependency array includes `user`

    /* working
    useEffect(() => {
      if (user) {
          console.log(user.userId);
      }
  }, [user]); // This will run when the `user` object updates

  */ 
  
    // const userId = user.userId;


	const { logout } = useDescope();

    const handleLogout = useCallback(() => {
      logout();
  }, [logout]);

  useEffect(() => {
    // Load search terms from local storage on component mount
    const storedSearchTerms = localStorage.getItem('searchTerms');
    const storedSummaryData = localStorage.getItem('summaryData');
    if (storedSearchTerms) {
      setSearchTerms(JSON.parse(storedSearchTerms));
    }
    if (storedSummaryData) {
      setSummaryData(JSON.parse(storedSummaryData));
    }
  }, []);
  

  useEffect(() => {
    // Save search terms to local storage whenever it changes
    localStorage.setItem('searchTerms', JSON.stringify(searchTerms));
  }, [searchTerms]);
  
  useEffect(() => {
    // Save summary data to local storage whenever it changes
    localStorage.setItem('summaryData', JSON.stringify(summaryData));
  }, [summaryData]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null); // Reset the error state after the timeout
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  const handleSelectTab = (tabKey) => {
    console.log('Selected tab:', tabKey);
    setActiveTab(tabKey);
  };

  const handleSearch = e => {
    e.preventDefault();

    if (searchTerm) {
      setLoading(true); // Start the loading state
  
      setSearchTerms(prevSearchTerms => {
        const updatedTerms = [...prevSearchTerms, searchTerm];
        setActiveTab(`tab${updatedTerms.length}`);  // Switch to the latest tab
        return updatedTerms;
      });
  
      // Save search terms in local storage
      const savedSearchTerms = JSON.parse(localStorage.getItem('searchTerms')) || [];
      const updatedSearchTerms = [...savedSearchTerms, searchTerm];
      localStorage.setItem('searchTerms', JSON.stringify(updatedSearchTerms));
      
        // test in prod
     fetch('https://api.freshticker.com/search', {
        // test in local below
     // fetch('http://localhost:8080/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ searchTerm }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to send search term to server');
          }
          return response.json();
        })
        .then(responseData => {
          setLoading(false); // End the loading state when data is received
          const searchResults = responseData[searchTerm];
          const summaryDataForTerm = searchResults.map((data) => ({
            imageUrl: data.imageUrl,
            url: data.url,
            articleTitle: data.articleTitle,
            summary: data.davinciSummary,
          }));

          // Save summaryDataForTerm in local storage
            const savedSummaryData = JSON.parse(localStorage.getItem('summaryData')) || {};
            const updatedSummaryData = {
              ...savedSummaryData,
              [searchTerm]: summaryDataForTerm,
            };
            localStorage.setItem('summaryData', JSON.stringify(updatedSummaryData));

          setSummaryData((prevSummaryData) => ({
            ...prevSummaryData,
            [searchTerm]: summaryDataForTerm,
          }));
        })
        .catch(error => {
          setLoading(false);
          setError('No data found, please try again or enter a different search term');
          console.error(error.message);
        });        
    }
  };  
  
  const handleDeleteSearchTerm = (searchTermIndex) => {
    setSearchTerms(prevSearchTerms => prevSearchTerms.filter((term, index) => index !== searchTermIndex));
  };

  const renderSearchTerms = () => {
    return searchTerms.map((term, index) => {
      return (
        <OverlayTrigger
          key={index}
          placement='bottom'
          overlay={
            <Tooltip id={`tooltip-${index}`}>
              Click to remove {term} from your watchlist
            </Tooltip>
          }
        >
          <span className="badge bg-secondary me-2 mb-2">{term}
            <button type="button" className="btn-close ms-2" onClick={() => handleDeleteSearchTerm(index)}></button>
          </span>
        </OverlayTrigger>
      )})};
  
      // Use Pendo for tracking user
      
      const [isPendoInitialized, setPendoInitialized] = useState(false);
      useEffect(() => {
        // Check if user data is available and Pendo is not already initialized
        if (user && user.userId && !isPendoInitialized) {
            // Initialize Pendo here
            window.pendo.initialize({
                visitor: {
                    id: '12345',  // Use user.userId here
                    // ... other visitor properties
                },
                account: {
                    id: user.userId,  // Use user.userId here for account ID
                    // ... other account properties
                },
                // ... other Pendo initialization properties
            });

            // Set flag to indicate Pendo has been initialized
            setPendoInitialized(true);
        }
    }, [user, isPendoInitialized]); // Depend on user and isPendoInitialized state


      if (isSessionLoading || isUserLoading) {
        return <p>Loading...</p>;
    }
  
    if (isAuthenticated) {
return (
  <>
      <Navbar bg="light" expand="lg" style={{ backgroundColor: "green"}}>
        <Container fluid>
          <Navbar.Brand href="#">Freshticker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Form onSubmit={handleSearch} className="d-flex">
                <FormControl type="text" placeholder="Enter ticker e.g. AAPL" className="me-2" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                <Button variant="primary" type="submit">Search</Button>
              </Form>
              <div className="d-flex search-chips">{renderSearchTerms().slice(0, 3)}</div>
            </Nav>
            <Nav className="ms-auto">
              <Nav.Link href="#">Settings</Nav.Link>
              <Nav.Link href="#">Log out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Tab.Container activeKey={activeTab} onSelect={handleSelectTab}>
          <Row>
            <Col>
              <Nav variant="tabs">
                {searchTerms.map((term, index) => (
                  <Nav.Item key={index}>
                  <Nav.Link eventKey={`tab${index + 1}`} onClick={() => setActiveTab(`tab${index + 1}`)}>
                    {term}
                  </Nav.Link>
                </Nav.Item>
                ))}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
            {error && <ErrorBanner error={error} />}
            <Tab.Content>
            {searchTerms.map((term, index) => {
              const activeTab = `tab${index + 1}`;
              const currentSummaryData = summaryData[term] || [];
              const selectedSummary = currentSummaryData.find((summary) => summary.url === selectedUrl);
                  return (
                    <Tab.Pane key={index} eventKey={activeTab}>
                      <Container fluid>
                        <Row>
                        <Col md={3} className="bg-secondary" style={{ backgroundColor: "white" }}>
  <div key={index} style={{ height: "calc(100vh - 70px)", overflowY: "scroll" }}>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    ) : (
      currentSummaryData.map((summary, index) => (
        <Card className="my-4" style={{ borderRadius: '20px' }} onClick={() => {setSelectedUrl(summary.url);}} key={index}>
          <Row>
            <div className="d-flex align-items-center">
              <Col md={4}>
                <Card.Img variant="left" src={summary.imageUrl} style={{ width: "100px", height: "75px", borderRadius: '20px' }} className="p-2" />
              </Col>
              <Col md={8}>
                <Card.Title className="p-2">
                  {summary.articleTitle.length > 75
                    ? summary.articleTitle.substring(0, 75) + "..."
                    : summary.articleTitle}
                </Card.Title>
              </Col>
            </div>
          </Row>
        </Card>
      ))
    )}
  </div>
</Col>
                          <Col md={9} className="bg-light">
                            {selectedUrl ? (
                              <Card className="my-4" style={{ borderRadius: "20px" }}>
                                <Card.Body>
                                  <Card.Title>Article summary</Card.Title>
                                  <Card.Text>
                                    {currentSummaryData.map((summary, index) => {
                                      if (summary.url === selectedUrl) {
                                        return (
                                          <div key={index}>
                                            {summary.summary.split("*").filter(Boolean).map((item, innerIndex) => (
                                              <div key={innerIndex}>
                                                {innerIndex !== 0 && <hr />}
                                                {item.trim()}
                                              </div>
                                            ))}
                                            <br></br>
                                            <Button variant="primary" href={summary.url} target="_blank" rel="noopener noreferrer">
                                              Read full article
                                            </Button>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            ) : (
                              <Card className="my-4" style={{ borderRadius: "20px" }}>
                                <Card.Body>
                                  <Card.Title>Select an article to read more. Happy reading!</Card.Title>
                                </Card.Body>
                              </Card>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
    </>
  );    
              }

return <p>You are not logged in. Head to <a href="https://www.freshticker.com/login">our login portal</a> to get access.
</p>;
};
export default App;
