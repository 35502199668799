import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from '@descope/react-sdk'
import Home from "./components/Dashboard";
// Removed since we don't need /Dashboard anymore; import Dashboard from "./components/Dashboard";
import Login from "./components/Login";

function App() {
  return (
    <AuthProvider projectId="P2QJgXebbgrNP3vVD6vOwxdjIADq">
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

// Removed /Dashboard from Routes           <Route path="/dashboard" element={<Dashboard />} /> 
// test

export default App;
