import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDescope, useSession, useUser, getSessionToken } from '@descope/react-sdk';
import { Descope } from '@descope/react-sdk';
import { useEffect } from 'react';

function Login() {
  const navigate = useNavigate();
  const { isAuthenticated, session, getSessionToken } = useSession();

  useEffect(() => {
    // Check if the user is authenticated or if a session token exists
    if (isAuthenticated || session) {
      navigate('/');
    }
  }, [isAuthenticated, session, navigate]); // Dependencies

  return (
    <div>
      <Descope
        flowId="sign-up-or-in"
        theme="light"
        onSuccess={async (e) => {
          console.log(e.detail.user.name);
          console.log(e.detail.user.email);
          navigate('/');  // Navigate to Home on successful login

          const sessionToken = getSessionToken();

          // example fetch call with authentication header
          fetch('http://localhost:3000/auth', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer '+sessionToken,
            }
          })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            // handle the response data as required
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }}
        onError={(err) => {
          console.log("Error!", err);
        }}
      />
    </div>
  );
}

export default Login;
